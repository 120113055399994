import React from 'react';
import Nav from '../Nav/Nav';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import About from '../About/About';
import Projects from '../Projects/Projects';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';
import './App.css';  // Assuming App.css is within the App folder

function App() {
  return (
      <div>
        <Nav />
        <main>
          <About />
          <Projects />
          <ContactForm />
        </main>
        <Footer />
      </div>
  );
}

export default App;
