import React from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import s from './Card.module.css'; // Make sure this path is correct

// Animation variants for the card image
const imageVariants = {
  offscreen: {
    opacity: 0,
    y: 50, // Start slightly below
  },
  onscreen: {
    opacity: 1,
    y: 0, // Move to original position
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
      duration: 1, // Adjust the timing as needed
    },
  },
};

// Animation variants for the card text
const textVariants = {
  offscreen: {
    opacity: 0,
    x: -50, // Start off-screen from the left
  },
  onscreen: {
    opacity: 1,
    x: 0, // Move to original position
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 20,
      duration: 1, // Adjust the timing as needed
    },
  },
};

const Card = ({ image, mobileImage, headline, text }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <motion.div
      className={s.card}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 }
            }}
    >
      <motion.img
        src={isMobile ? mobileImage : image}
        alt={headline}
        className={s.cardImage}
        style={{ width: '100%', height: 'auto' }}
        variants={imageVariants} // Animate the image
        initial="offscreen"
        whileInView="onscreen"
      />
      <motion.h5
        className={s.cardTitle}
        variants={textVariants}
        initial="offscreen"
        whileInView="onscreen"
      >
        {headline}
      </motion.h5>
      <motion.p
        className={s.cardText}
        variants={textVariants}
        initial="offscreen"
        whileInView="onscreen"
      >
        {text}
      </motion.p>
    </motion.div>
  );
};

export default Card;
