// Projects.jsx
import React, { useMemo } from 'react';
import s from './Projects.module.css';
import Card from './Card'; 
import Slide from './Slide'; 
import { motion } from 'framer-motion';


function Projects() {
    // State hooks for loading and error handling

    const services =  useMemo(() => [
        {
            id: 1,
            image: "/images/imagecircle1.svg",
            mobileImage: "images/sectionMobImg1.svg",
            headline: 'Elevate Your Brand on Social Media',
            text: "Take your Instagram presence to the next level with expert social media management. I specialize in crafting engaging content, growing your audience, and strengthening your brand's online influence. Let me handle the details, so you can focus on running your business."
        },
        {
            id: 2,
            image: '/images/imagecircle2.svg',
            mobileImage: "images/sectionMobImg2.svg",
            headline: 'Build Your Perfect Online Presence',
            text: "Create a stunning, responsive website that captures your brand's essence. Whether you're looking for a Wix, WordPress, or Shopify site, I bring your vision to life with custom designs that not only look great but also perform flawlessly across all devices."
        },
        {
            id: 3,
            image: "/images/imagecircle3.svg",
            mobileImage: "images/sectionMobImg3.svg",
            headline: "Logo Designs That Define Brands",
            text: "Your logo is the face of your brand. I design memorable, impactful logos that resonate with your target audience and stand out in the marketplace. Start your brand’s journey with a logo that speaks volumes about your business’s values and vision."
        }
    ] ,[]);

    return (
    <div className={s.container}>
        <div className={s.partOne} id='about'>
            <div className={s.partOneLeft}>
                <div className={s.imageOne}></div>
            </div>
            <div className={s.partOneRight}>
                <h2 className={s.title}>Bringing <br></br> your ideas to life </h2>
                <p className={s.text}>Hi, I’m Gabrielle Zohar, your go-to for all things web design and development. I create custom websites that look great and run smoothly on platforms like WordPress, Shopify, and Wix. Whether you need a sleek online store, a catchy landing page, or something unique, I’m here to help bring your vision to life. My focus is on making sites that are not only beautiful but also user-friendly and optimized for any device. Let’s make your online presence pop and get your business booming. Ready to start?</p>
            </div>
        </div>
        <div className={s.partTwo}>
            <div className={s.partTwoHeadline}></div>
            <div className={s.partTwoServices} >
            {services.map(service => (
                    <Card key={service.id} image={service.image} mobileImage={service.mobileImage} headline={service.headline} text={service.text} />
                ))}

            </div>    
            
        </div>
        <div className={s.partThree} id='projects'> 
            <div className={s.partThreeheadline}></div>
            <div className={s.partThreeProjectsContainer}>
                <Slide 
                    name="RENT HOUSE"
                    websiteUrl="https://renthousetlv.com"
                    websiteLabel="Renthouse.com"
                    socialUrl="https://www.instagram.com/rent_houseofficial/"
                    socialLabel="@rent_houseofficial account"
                    backgroundImageUrl="/images/renthouseBG.png"
                    mobileBackgroundImageUrl="/images/renthouseBGmobile.png"
                    image1Url="/images/renthouseImg1.png"
                    mobileImage1Url="/images/renthouseImg1.png"
                    image2Url="/images/rentHouseImg2.png"
                    mobileImage2Url="/images/rentHouseImg2.png"
                    summary="Rent House is your go-to online platform for renting high-end clothing. I've developed a responsive e-commerce store using Shopify, ensuring a seamless shopping experience. I also manage the social account and create captivating content, making Rent House not just a service, but a fashion statement."
                />
                <Slide
                    name="MESHKEK PARTOUCHE"
                    websiteUrl="https://meshekpartouche.com"
                    websiteLabel="Meshekpartouche.com"
                    socialUrl=""
                    socialLabel=""
                    backgroundImageUrl="/images/meshekBG.png"
                    mobileBackgroundImageUrl="/images/meshekBGmobile.jpg"
                    image1Url="/images/meshekImg1.png"
                    mobileImage1Url="/images/meshekImg1.png"
                    image2Url="/images/meshekImg2.png"
                    mobileImage2Url="/images/meshekImg2.png"
                    summary="Meshek Partouche offers an idyllic retreat at a boutique villa set over 5 dunams with a resort-like atmosphere. Whether you're looking for a pampering vacation, hosting boutique events, or planning a photo shoot, this venue is designed to impress. 
I designed the responsive WordPress landing page and crafted the unique brand logo that captures the essence of Meshek Partouche's tranquil luxury."
                />
                <Slide
                    name="AKTO SPACE"
                    websiteUrl="https://aktospace.com"
                    websiteLabel="Aktospace.com"
                    socialUrl=""
                    socialLabel=""
                    backgroundImageUrl="/images/aktoBG.png"
                    mobileBackgroundImageUrl="/images/aktoBG.png"
                    image1Url="/images/aktoImg1.jpg"
                    mobileImage1Url="/images/aktoImg1.jpg"
                    image2Url="/images/aktoImg2.jpg"
                    mobileImage2Url="/images/aktoImg2.jpg"
                    summary="Akto Space places employee well-being at the forefront of the workday. I developed a responsive WordPress website that showcases their well-being practice rooms, offering body stretches, mindfulness, Pilates, and more. The site enables users to explore exercises and book sessions easily, making well-being a seamless part of the workday. Discover how Akto Space's design, combined with my technical expertise, enhances productivity, morale, and overall success."
                />
                   <Slide
                    name="AGAT KIKAR HAMEDINA "
                    websiteUrl="https://www.agatonline.co.il/"
                    websiteLabel="Agatonline.com"
                    socialUrl=""
                    socialLabel=""
                    backgroundImageUrl="/images/Golden-Goose.jpg"
                    mobileBackgroundImageUrl="/images/Golden-Goose.jpg"
                    image1Url="/images/agatImg1.jpg"
                    mobileImage1Url="/images/agatImg1.jpg"
                    image2Url="/images/agatImg2.jpg"
                    mobileImage2Url="/images/agatImg2.jpg"
                    summary="Agat Kikar Hamedina
The flagship store of Golden Goose, the exclusive importer and distributor in Israel for the Italian brand Golden Goose. Offering sneakers for women, men, and children in all sizes, along with a range of dresses and more. I designed and built their website using Wix."
                />
            </div>
        </div>
    </div>

    );
}

export default Projects;
