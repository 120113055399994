import React from 'react';
import s from './About.module.css';
import { motion } from 'framer-motion';

function About() {
  const logoVariants = {
    hidden: { x: -100, opacity: 0 },  // Slide from the left
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
    },
  };
  // Animation variants for the title (slide from the top)
  const titleVariants = {
    hidden: { y: -100, opacity: 0 },  // Slide from the top
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.2 },
    },
  };
  const buttonVariants = {
    hidden: { y: 100, opacity: 0 },  // Slide from the bottom
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.4 },
    },
  };

  const handleScrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className={s.about}>
        {/* <img className={s.logo} src='/images/about-6.svg'/> */}
        <motion.img
        className={s.logo}
        src='/images/about-6.svg'
        alt="Logo"
        variants={logoVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}  // Trigger when in view
        />
        {/* <img className={s.title} src='/images/about-8.svg'/> */}
        <motion.img
        className={s.title}
        src='/images/about-8.svg'
        alt="Title"
        variants={titleVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}  // Trigger when in view
        />
        <motion.button
        className={s.btn}
        onClick={handleScrollToContact}
        variants={buttonVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}  // Trigger when in view
        whileHover={{ scale: 1.1 }}   // Optional hover effect
        whileTap={{ scale: 0.9 }}     // Optional click effect
        >
        Let's Start
        </motion.button>
        {/* <button className={s.btn} onClick={handleScrollToContact}>let's start</button> */}
    </div>
  );
}

export default About;
