// src/ContactForm/ContactForm.jsx
import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import s from './ContactForm.module.css';

function ContactForm() {
  const [state, handleSubmit] = useForm("mqakknbl");
  const [isPopupVisible, setPopupVisible] = useState(false);

  // Close pop-up function
  const closePopup = () => {
    setPopupVisible(false);
  };

  // Handle form submission
  const onSubmit = (event) => {
    handleSubmit(event).then(() => {
      if (state.succeeded) {
        setPopupVisible(true);
      }
    });
  };

  return (
    <>
      <form id='contact' className={s.form} onSubmit={onSubmit}>
        <h4>Work With Me</h4>
        <input
          className={s.input}
          type="text"
          name="first-name"
          placeholder="First Name"
          required
        />
        <input
          className={s.input}
          type="text"
          name="last-name"
          placeholder="Last Name"
          required
        />
        <input
          className={s.input}
          type="email"
          name="email"
          placeholder="Email"
          required
        />
        <input
          className={s.input}
          type="tel"
          name="phone"
          placeholder="Phone Number"
          required
        />
        <textarea
          className={s.textArea}
          name="message"
          placeholder="Your message"
          required
        />
        <button className={s.btn} type="submit" disabled={state.submitting}>
          Submit
        </button>
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </form>

      {/* Pop-up Component */}
      {isPopupVisible && (
        <div className={s.popup}>
          <div className={s.popupContent}>
            <h3>Thank you for your message!</h3>
            <p>We will get back to you soon.</p>
            <img className={s.popupLogo} src="/images/about-5.svg" alt="Popup Logo" />
            <button className={s.popupClose} onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactForm;
