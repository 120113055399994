import React from 'react';
import s from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={s.footer}>
            <div className={s.footerGrid}>
                <div className={s.footerColumn}>
                    <h4 className={s.footerHeading}>About</h4>
                    <a href="#about" className={s.footerLink}>Learn More</a>
                </div>
                <div className={s.footerColumn}>
                    <h4 className={s.footerHeading}>Projects</h4>
                    <a href="#projects" className={s.footerLink}>Recent Projects</a>
                </div>
                <div className={s.footerColumn}>
                    <h4 className={s.footerHeading}>Contact</h4>
                    <a href="#contact" className={s.footerLink}>Get in Touch</a>
                </div>
                <div className={s.footerColumn}>
                    <h4 className={s.footerHeading}>Follow Us</h4>
                    <a href="https://www.instagram.com/getyoursites/?igsh=cG95eGFuZXR2aTNz&utm_source=qr" className={s.footerLink}>Instagram</a>
                </div>
            </div>
            <div className={s.footerCopy}>
                © 2024 by Gabrielle Zohar. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
