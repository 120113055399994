import React, { useState } from 'react';
import s from './Nav.module.css';

function Nav() {
    const [isOpen, setIsOpen] = useState(false);

    const handleNavLinkClick = (event) => {
        event.preventDefault();
        const targetId = event.currentTarget.getAttribute('href').slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(false); // Close the menu after clicking (for mobile)
        }
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={s.nav}>
            {/* Trigger for opening the menu */}
            <a href="#" className={s.navTrigger} onClick={toggleMenu}>
                <span className={`${s.navIcon} ${isOpen ? s.navIconActive : ''}`}></span>
            </a>

            {/* Navigation menu */}
            <ul className={`${s.ul} ${isOpen ? s.open : s.closed}`}>
                <li><a className={s.navLink} href="#about" onClick={handleNavLinkClick}>ABOUT</a></li>
                <li><a className={s.navLink} href="#projects" onClick={handleNavLinkClick}>PROJECTS</a></li>
                <li><a className={s.navLink} href="#contact" onClick={handleNavLinkClick}>CONTACT</a></li>
            </ul>
        </nav>
    );
}

export default Nav;
