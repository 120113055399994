import React from 'react';
import s from './Slide.module.css';  // Ensure you have corresponding CSS
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

// Text animation variants for sliding in from the left
const textVariants = {
    offscreen: {
        x: -100,  // Start off-screen from the left
        opacity: 0  // Start fully transparent
    },
    onscreen: {
        x: 0,  // End at the natural horizontal position
        opacity: 1,  // Animate to full opacity
        transition: {
            type: "spring",  // Use a spring type for a natural feel
            stiffness: 120,
            damping: 20,  
            mass: 0.75,
            duration: 1  // Duration of the animation
        }
    }
};
const image1Variants = {
    offscreen: {
        y: 100,  // Start below the view
        opacity: 0
    },
    onscreen: {
        y: 0,  // End at the natural vertical position
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 120,
            damping: 20,
            duration: 1.5,
            when: "beforeChildren",
            ease: [0.42, 0, 0.58, 1]
        }
    }
};
const image2Variants = {
    offscreen: {
        y: -100,  // Start above the view
        opacity: 0
    },
    onscreen: {
        y: 0,  // End at the natural vertical position
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 120,
            damping: 20,
            duration: 1.5,
            when: "beforeChildren",
            ease: [0.42, 0, 0.58, 1]  
        }
    }
};
// // Image animation variants
// const imageVariants = {
//     offscreen: {
//         opacity: 0,
//         scale: 0.9
//     },
//     onscreen: {
//         opacity: 1,
//         scale: 1,
//         transition: {
//             type: "spring",
//             stiffness: 100,
//             damping: 10,
//             duration: 0.5
//         }
//     }
// };

const Slide = ({ name, websiteUrl, websiteLabel, socialUrl, socialLabel, backgroundImageUrl, mobileBackgroundImageUrl, image1Url, mobileImage1Url, image2Url, mobileImage2Url , summary}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const finalBackgroundImageUrl = isMobile && mobileBackgroundImageUrl ? mobileBackgroundImageUrl : backgroundImageUrl;
    const finalImage1Url = isMobile && mobileImage1Url ? mobileImage1Url : image1Url;
    const finalImage2Url = isMobile && mobileImage2Url ? mobileImage2Url : image2Url;

    return (
        <motion.div
            className={s.partThreeProject}
            style={{ backgroundImage: `url(${finalBackgroundImageUrl})` }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 }
            }}
        >
            <div className={s.contentSection}>
            <div className={s.textGroup}>
                    <motion.h4
                        variants={textVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        className={s.projectTitle}
                    >
                        {name}
                    </motion.h4>
                    <motion.p
                        className={s.summaryText}
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1, transition: { type: "spring", stiffness: 120, damping: 20, duration: 1 } }}
                    >
                        {summary}
                    </motion.p>
                    <motion.div
                        variants={textVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        className={s.linkSection}
                    >
                        <i className="fa-solid fa-arrow-right"></i>
                        <p>Visit <a href={websiteUrl}>{websiteLabel}</a></p>
                    </motion.div>
                    {socialUrl && (
                        <motion.div
                            variants={textVariants}
                            initial="offscreen"
                            whileInView="onscreen"
                            className={s.linkSection}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                            <p>Visit <a href={socialUrl}>{socialLabel}</a></p>
                        </motion.div>
                    )}
                </div>
                <div className={s.imageGroup}>
                    <motion.img
                        src={finalImage1Url}
                        alt={`Image for ${name} 1`}
                        variants={image1Variants}
                        initial="offscreen"
                        whileInView="onscreen"
                        className={s.slideImage}
                    />
                    <motion.img
                        src={finalImage2Url}
                        alt={`Image for ${name} 2`}
                        variants={image2Variants}
                        initial="offscreen"
                        whileInView="onscreen"
                        className={s.slideImage}
                    />
                </div>
              
            </div>
        </motion.div>
    );
};

export default Slide;
